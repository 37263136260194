
//	Dependencies
import React from 'react';

// App
import { HomeView, HomeViewCanvas } from 'ui/views';


//
//	RAYC / Pages / Index
//


function IndexPage( props ) {
	return <HomeView { ...props } />;
}

// IndexPage.CanvasComponent = HomeViewCanvas;


export default IndexPage;
